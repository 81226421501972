<template>
	<div class="input-container">
		<h2>Agendamentos</h2>
		<ReportsFilter 
			group="Procedimentos" 
			tab="Procedimento" 
			v-on:onChangeFilters="getFilters" 
			:headers="getAllHeadersOptions()" 
		/>
		<ReportResume 
			v-if="table.length > 0" 
			:data="data"
			:reloadResume="reloadResume"
		/>
		<div v-if="table.length > 0">
			<ReportsTable 
				:headers="headers" 
				:rows="table" 
			/>
			<div class="pagination-position">
				<b-pagination 
					v-model="page" 
					:total-rows="count" 
					:per-page="limit" 
					first-number 
					last-number 
					size="sm" 
				/>
			</div>
		</div>
		<div class="alert-container" v-else>
			<p v-if="!loadedData">Configure os filtros e clique no botão “Gerar relatório" para gerar um relatório</p>
			<p v-else>Nenhum dado a ser exibido.</p>
		</div>
	</div>
</template>

<script>
import { getCurrentClinic } from '../../utils/localStorageManager'
export default {
	name: 'schedule',
	components: {
		ReportsFilter: () => import('./ReportsFilters.vue'),
		ReportResume: () => import('./ReportResume.vue'),
		ReportsTable: () => import('./ReportsTable.vue')
	},
	data() {
		return {
			clinic: getCurrentClinic(),
			loadedData: false,
			data: {
				type: "Procedimento",
			},
			page: 1,
			limit: 0,
			count: 0,
			table: {},
			headers: [],
			reloadResume: 1
		}
	},
	methods: {
		getAllHeadersOptions() {
			const options = ['Todos'].concat(this.getAllHeaders())
			return options
		},
		getAllHeaders() {
			return [
				'Data',
				'Hora do início',
				// 'Cód. SUS',
                // 'Cód. TISS',
				'Procedimento',
				'Tipo de procedimento',
				'Nome do paciente',
				'Dur. do Procedimento',
				'Convênio',
                'Situação do agendamento',
                'Plano',
                'Valor',
				'Profissional'
			]
		},
		getFilters(newValue) {
			this.data.filters = newValue
			this.getProceduresReport()
		},
		getProceduresReport() {
			const isLoading = this.$loading.show()
			this.api.getManagementReport(this.data, this.page)
				.then(res => {
					this.headers = []
					this.getAllHeaders().map((head) => {
						if (!this.data.filters?.fieldsToShow || this.data.filters?.fieldsToShow?.indexOf(head) !== -1)
							this.headers.push(head)
					})
					this.table = res.data.rows
					this.limit = res.data.per_page
					this.count = res.data.total
					this.loadedData = true
					this.reloadResume++
				})
				.catch(err => {
					this.$toast.error(err.message)
				})
				.finally(() => {
					isLoading.hide()
				})
		},
	},
	watch: {
		page: function () { this.getProceduresReport() },
	}
}
</script>
<style lang="scss" scoped>
h2 {
	font-family: 'Red Hat Display';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 134%;
	display: flex;
	align-items: center;
	letter-spacing: -0.01em;
	color: #0C1D59;
	padding: 30px 0;
}
.input-container {
	display: flex;
	flex-direction: column;
}
.alert-container {
	margin: 10% 0px;
	p {
		font-family: 'Nunito Sans';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 150%;
		text-align: center;
		color: #525C7A;
	}
}
</style>